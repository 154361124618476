import React from 'react'

import css from './styles.module.scss'
import Image from '../Image'

// component
// ==========================================================================================
const ImageFullWidth = ({ image, imageSharp, columns }) => {
  if (!(imageSharp || image)) {
    return null
  }

  return (
    <section className={css.wrap}>
      <div className="row">
        <div className={columns}>
          <Image image={image} {...imageSharp} className={css.image} />
        </div>
      </div>
    </section>
  )
}

export default ImageFullWidth
