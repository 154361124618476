import React from 'react'

import css from './styles.module.scss'
import Title from '../Title'

// component
// ==========================================================================================
const ArticleHeading = ({ type, title, author, eventDate, eventAddress }) => {
  return (
    <div className={css.wrap}>
      <div className="row">
        <div className="col-sm-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
          <div className={css.type}>{type}</div>
          <Title tag="h1" content={title} />
          {type?.toLowerCase() === 'event' ? (
            <div className={css.eventData}>
              {eventDate}
              {eventAddress && (
                <>
                  <br />
                  {eventAddress}
                </>
              )}
            </div>
          ) : (
            <div className={css.author}>{author}</div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArticleHeading
